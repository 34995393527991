<template>
  <div class="setting_form deep-links clear">
    <div class="white_box">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Deep Links</h2>
            <p>Deep links are a type of link that send users directly to an app instead of a website or a store. They
              are used to send users straight to specific in-app locations, saving users the time and energy locating a
              particular page themselves – significantly improving the user experience and conversion rate.</p>
          </div>
        </div>
        <div class="settings_component">
          <div class="container ">
            <clip-loader :size="'22px'" class="align-items-center pt-5 pb-5"
                         v-if="getDeepLinksLoader.deeplinksListLoader" :color="'#1d8bfa'"></clip-loader>
            <div v-else class="settings_component_inner setting_page d-flex align-items-start">

              <div class="setting-sidebar">
                <div class="title">CATEGORIES</div>
                <div class="search_input_block mt-4 no_animation">
                  <input v-model="searchDeepLinks" placeholder="Search for an app" type="text" class=""/>
                </div>
                <ul v-if="getSelectedDeepLink && getSelectedDeepLink.description" class="mt-3">
                  <li @click="selectCategory(item.route_name)" v-for="item in getDeepLinksSettingsList">
                    <router-link :to="{name:item.route_name}">
                      <i :class="item.icon"></i>
                      <span>{{ item.name }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>

              <transition name="slide">
                <router-view></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default ({
  name: 'DeepLinksSettings',
  data () {
    return {
      searchDeepLinks: ''
    }
  },
  created () {
    this.getSiteDetails
    this.resetDeepLinks()
    this.showDeepLinks()
  },
  computed: {
    ...mapGetters(['getDeepLinksSettingsList', 'getDeepLinksLoader', 'getFilteredDeepLinksList', 'getDeepLinksItems', 'getSelectedDeepLink'])
  },
  methods: {
    ...mapActions(['showDeepLinks', 'selectCategory', 'setFilteredDeepLinksLists', 'resetDeepLinks'])
  },
  watch: {
    /**
     * In case of searching if have data in search field then we get search result from deep links brands
     * @param value
     * @returns {Promise<void>}
     */
    async 'searchDeepLinks' (value) {
      this.selectCategory(this.$route.name)
      if (value && value.length) {
        this.setFilteredDeepLinksLists({
          name: 'Search for : ' + value,
          description: '',
          items: this.getDeepLinksItems.filter(item => item.name.toLowerCase().includes(value))
        })
      }
    }
  }
})
</script>
<style lang="less" scoped>
.deep-links {
  .white_box {
    .box_inner {
      .box_head {
        border-bottom: 1px solid #d0d6e5;
      }

      .settings_component {
        background: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .container {
          .settings_component_inner {
            padding: 0px 10px;

            .setting-sidebar {
              .title {
                font-size: small;
                margin-top: 28px;
                letter-spacing: 0.7px;
              }

              .search_input_block {
                overflow: unset;

                input {
                  background: none;
                  padding: 8px 15px;
                  border: solid 1px #d0d6e5;
                }

                margin-bottom: 20px;
              }

              ul {
                width: 90%;

                li {
                  a.active_tab {
                    background-color: rgba(69, 162, 255, 0.1);
                    border: none;

                    i, span {
                      color: #1d8bfa;
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
